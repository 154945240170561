import { LitElement, html, css } from 'lit';
// import { TileCard } from 'formula_one'



let Footer = class Footer extends LitElement {
    render() {
        return html `
        <div class="footer">
          <div class="color-bg-subtle">
            <div class="container-xl">
              <ul class="containter-xl left-list">
                <li class="mr-3">
                  © 2024 FlightBlue, LLC.
                </li>

                <!-- <li class="mr-3">
                  <a href="https://docs.github.com/site-policy/github-terms/github-terms-of-service">Terms</a>
                </li>

                <li class="mr-3">
                  <a href="https://docs.github.com/site-policy/privacy-policies/github-privacy-statement">Privacy</a>
                  (<a href="https://github.com/github/site-policy/pull/582">Updated 08/2022</a>)
                </li>

                <li class="mr-3">
                  <a>Sitemap</a>
                </li>

                <li class="mr-3">
                  <a href="/git-guides">What is FlightBlue?</a>
                </li> -->
              </ul>
              <ul class="containter-xl right-list">
              <li class="mr-3 flex-self-start">
                <a href="https://www.linkedin.com/in/alexander-klandorf" data-flight-event="{&quot;category&quot;:&quot;Footer&quot;,&quot;action&quot;:&quot;go to Linkedin&quot;,&quot;label&quot;:&quot;text:linkedin&quot;}" class="color-fg-subtle">
                  <img src="https://github.githubassets.com/images/modules/site/icons/footer/linkedin.svg" width="19" height="18" class="d-block" loading="lazy" decoding="async" alt="Linkedin icon">
                  <span class="sr-only">FlightBlue on LinkedIn</span>
                </a>
              </li>
              <li>
                <a href="https://github.com/frodnalk" data-flight-event="{&quot;category&quot;:&quot;Footer&quot;,&quot;action&quot;:&quot;go to github's org&quot;,&quot;label&quot;:&quot;text:github&quot;}" class="color-fg-subtle">
                  <img src="https://github.githubassets.com/images/modules/site/icons/footer/github-mark.svg" width="20" height="20" class="d-block" loading="lazy" decoding="async" alt="GitHub mark">
                  <span class="sr-only">FlightBlue on GitHub</span>
                </a>
              </li>
            </ul>
            </div>
          </div>
        </div>
    `;
    }
    firstUpdated (changedProperties) {
    //     console.log("test");

    //     const header = this.shadowRoot.querySelector("#header");
    //     // const menu_icon = this.shadowRoot.querySelector("#menu-icon");
    //     const nav = this.shadowRoot.querySelector("nav");

    //     header.innerHTML += '<div id="menu-icon"><span class="first"></span><span class="second"></span><span class="third"></span></div>';
    //     const menu_icon = this.shadowRoot.querySelector("#menu-icon");
    //     menu_icon.addEventListener("click", function(){
    //         nav.slideToggle();
    //         this.toggleClass("active");
    // });
    
    
      }
};
Footer.styles = css `

// :host {
//   --h6-size: 0.875rem;
//   --base-size-4: 0.25rem;
//   --base-size-8: 0.5rem;
//   --base-size-12: 0.75rem;
//   --base-size-16: 0.8rem;
//   --base-size-20: 1.25rem;
//   --base-size-24: 1.5rem;
//   --base-size-28: 1.75rem;
//   --base-size-32: 2rem;
//   --base-size-36: 2.25rem;
//   --base-size-40: 2.5rem;
//   --base-size-44: 2.75rem;
//   --base-size-48: 3rem;
//   --base-size-64: 4rem;
//   --base-size-80: 5rem;
//   --base-size-96: 6rem;
//   --base-size-112: 7rem;
//   --base-size-128: 8rem;
//   --fgColor-muted: blue;
// }

.footer { 
  bottom: 0; 
  left: 0; 
  z-index: 10;
  background-color: #070415;
  width: 100%;

}

.color-bg-subtle {
  display: flex;

}

.container-xl {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.left-list {
  text-decoration: none;
  list-style: none;
  display: flex;
  align-items: center;


}

.right-list {
  text-decoration: none;
  list-style: none; 
  display: flex;
  padding-right: 3vw;

}

.mr-3 {
  margin-right: var(--base-size-16, 16px) !important;
  font-size: var(--h6-size, 12px) !important;
  color: white;
}

.mr-3 a {
    text-decoration: none;
    justify-content: space-between !important;
    color: var(--fgColor-muted, var(--color-fg-muted)) !important;
}

.mr-3 a:hover {
  background-color: yellow;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  word-wrap: normal;
  border: 0;
}

  `;


  


  customElements.define('lit-footer', Footer);