import { LitElement, html, css } from 'lit';
import 'fa-icons';

// import * as PythonShell from 'python-shell';


let Header = class Header extends LitElement {
    render() {
        return html `
        <div class="app-header">
        <div class="content">
          <nav role="navigation">
            <button id="menuToggle" class="app-header__menu">
                <input type="checkbox"/>
                  <span></span>
                  <span></span>
                  <span></span>
                <ul id="menu">
                  <li><a href="#">Home</a></li>
                  <!-- <li><a href="/auth/login">Auth</a></li> -->
                  <li><a href="/about">About</a></li>
                  <li><a href="/flight">Flight</a></li>
                  <li><a href="/blue">Blue</a></li>
                  <!-- <li><a href="/contact">Contact</a></li> -->
                </ul>
            </button>
          </nav>
        </div>
        <div class="app-header__item app-header__title">FlightBlue</div>
        <button class="app-header__menu">
          <fa-icon class="fas fa-bell"></fa-icon>
        </button>
        <button class="app-header__menu">
          <fa-icon class="fas fa-user"></fa-icon>
        </button>
      </div>
    `;
    }

    firstUpdated () {
    //     console.log("test");

        const header = this.shadowRoot.querySelectorAll("#menu a")
        // console.log(header.length)
        for (var i = 0; i < header.length; i++) {
          // console.log(i, header[i])


            // var element =  header
            // console.log(element)
            
            header[i].addEventListener('click', function(change) {

              // console.log(header[1].parentElement.parentElement.parentElement.children[0])
              header[1].parentElement.parentElement.parentElement.children[0].checked = false;
              // header[1].parentElement.parentElement.style.visibility = "hidden" ;

              // transform-origin: 5px 0px;
              // transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              //             background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              //             opacity 0.55s ease;
    
            })
  
          }


          
      }
};
Header.styles = css `

.app-header {
  position: sticky;
  top: 0;
  display: flex;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.42); 
  background-color: #3F51B5;
  color: #FFF;
  flex: 0 0 auto;
  z-index: 3;
}
.app-header__menu {
  flex: 0 0 auto;
  padding: 0 16px; 
  }

.app-header__item {
flex: 0 0 auto;
height: 48px;
line-height: 48px;
padding: 0 16px; 
}

.app-header__title {
flex: 1 1 auto; 
}
.app-header button {
height: 48px;
padding: 0 1em;
cursor: pointer;
color: #FFF;
outline: none;
border: 0;
background-color: transparent; 
}

.app-header button span {
  color: #FFF;
  }



.app-header button:hover {
background-color: #303F9F; 
}

.container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

a {
  text-decoration: none;
  color: #1E1E23;
  opacity:1;
  font-family: 'work sans', sans serif;
  font-size: 1.5em;
  font-weight: 400;
  transition: 200ms;

}

a:hover {
  opacity:0.5;
}

a:active {
  width: 70px;
  height: 74px;
}

ul {
  padding: 0;
  list-style-type: none;
}



.container {
  margin-top: 50px; 
  Display: flex;
  justify-content: center;
  align-items: center;
}
// .content {
//   // border: 1px solid #36383F;
//   // border-radius: 20px;
//   // width: 94%;
//   // height: 91%;
//   // background-color: #F5F6FA;
//   // overflow: hidden;
// }
// nav {
//   // background-color: #1E1E23;
//   // height: 65px;
// }


// #menuToggle {
//   // display: flex;
//   // flex-direction: column;
//   // position: relative;
//   // top: 25px;
//   // left: 25px;
//   // z-index: 1;
//   // -webkit-user-select: none;
//   // user-select: none;
// }

#menuToggle input
{
  display: flex;
  margin-left: -10px;
  margin-top: -10px;
  width: 50px;
  height: 42px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span
{
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383F;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  position: absolute;
  width: 180px;
  height: 100vh;
  box-shadow: 0 0 10px #85888C;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  background-color: #F5F6FA;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  transition-delay: 2s;
  
}




#menuToggle input:checked ~ ul
{
  transform: none;
}

  `;


  


  customElements.define('lit-header', Header);